import React from 'react';
import { Feature } from '../../components';
import './whatVC.css';

const WhatVC = () => {
  return (
    <div className='vc__whatvc section__margin' id="wvc">
      <div className='vc__whatvc-feature'>
        <Feature title="What is Voice Cloning?" text="Voice cloning is the process in which one uses a computer to generate the speech of a real individual, creating a clone of their specific, unique voice using AI."/>
      </div>
      <div className='vc__whatvc-heading'>
        <h1 className='gradient__text'>The possibilities are beyond your imagination</h1>
        <p>Explore the voice cloning</p>
      </div>
      <div className='vc__whatvc-container'>
        <Feature title="Catharsis" text="Hearing the deceased person’s voice with the provided speech can provide emotional relief to the family members of the deceased one."/>
        <Feature title="Audiobooks" text="Using AI voice cloning software, celebrity voices can be used to narrate books, autobiographies can be read by the author, and historical figures can tell their stories in their own voices. The result is an immersive, high-quality listening experience."/>
        <Feature title="Assistive Tech" text="Synthetic voices can be used to assist persons with disabilities or health issues that impact their speech. For example, people who are suffering from disorders like Parkinson’s Disease or ALS can enhance their ability to communicate using a synthetic version of their voice and TTS."/>
      </div>
    </div>
  )
}

export default WhatVC
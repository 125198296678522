//rafce : react functional component
import React from 'react';

import { Footer, Blog, Clone, Methods, WhatVC, Header } from './containers';

import { Navbar, CTA } from './components';

import './App.css';

const App = () => {
  return (
    <div className="App">
        <div className="gradient__bg">
          <Navbar />
          <Header />
        </div>
        <WhatVC />
        <Methods />
        <Clone />
        <Blog />
        <CTA />
        <Footer />
    </div>
  )
}

export default App
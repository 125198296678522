import React from 'react';
import './cta.css';

const CTA = () => {
  return (
    <div className='vc__cta'>
      <div className='vc__cta-content'>
        <p>Wanna give it a try??</p>
        <h3>Hit the button to get started.</h3>
      </div>
      <div className='vc__cta-btn'>
      <a href="#voiceClone"><button type='button'>Clone Voice</button></a>
      </div>
    </div>
  )
}

export default CTA
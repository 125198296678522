import React, { useState, useEffect } from 'react';
import './blog.css';
import { Sample } from '../../components';
import { voice } from './imports';
import axios from "axios";

const Blog = () => {

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    axios.get('https://rvs.ninja/api/voices/')
    // axios.get('http://127.0.0.1:8000/api/voices/')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const dateConversion = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate
  }

  return (
    <div className='vc__blog section__padding' id="samples">
      <div className='vc__blog-heading'>
        <h1 className='gradient__text'>Original and Generated Nepali voice..</h1>
      </div>
      <div className='vc__blog-container'>
        {/* <div className='vc__blog-container_groupA'>
          <Article imgUrl={voice} date="Feb 5, 2023" title="Text given by user" actor="Ravi"/>
        </div> */}
        <div className='vc__blog-container_groupB'>
          {/* slice() is used to create a new copy of the array before reversing it to avoid mutating the original array. */}
        {data.slice().reverse().map(item => (
          (item.favourite === true) && <Sample key={item.id} setSelected={setSelected} selected={selected} id={item.id} orgAudio={item.org_audio} genAudio={item.gen_audio} date={dateConversion(item.created_at)} title={item.text} actor={item.name}/>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Blog
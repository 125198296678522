import React from 'react';
import { Feature } from '../../components';
import './methods.css';

const methodsData = [
  {
    title: 'Encoder',
    text: "Encoder is responsible for feature extraction for the target voice which is given in as input. The speaker encoder does not focus on words but on the pitch, tone, ascent, etc. This way speaking of the same person will be clustered and can be easily distinguishable from another person."
  },
  {
    title: "Synthesizer",
    text: "The synthesizer uses the input text sequence and takes the speaker embedding as input and uses the Tacotron 2 architecture to generate output which is Mel-Spectrogram"
  },
  {
    title: "Vocoder",
    text: "Converting the Mel-Spectrogram to audio waves vocoder is used. WaveRNN is proposed to use as a vocoder. WaveRNN is a single-layer recurrent neural network for audio generation."
  }
]

const Methods = () => {
  return (
    <div className='vc__methods section__padding' id="methods">
      <div className='vc__methods-heading'>
        <h1 className='gradient__text'>The Future is Now and You Just Need To Realize It. Step into Future Today & Make it Happen.</h1>
        <p>Methodology for Nepali Voice Cloning</p>
      </div>
      <div className='vc__methods-container'>
        {methodsData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Methods
import React, { useCallback } from "react";
import "./dropzone.css";
import audio from "../../assets/dropzone_audio.png";
import { useDropzone } from "react-dropzone";

const DropZone = ({setFile,}) => {
  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragReject } =
    useDropzone({ onDrop, multiple: false, accept: {"audio/*": []} });

  return (
    <div className="vc__dropzone_container">
      <div {...getRootProps()} className="vc__dropzone-main">
        <input {...getInputProps()} />

        <div className={isDragReject ? ("vc__isDragReject") : ("vc__dropzone-content")}>
          <img src={audio} alt="audio" />

          {isDragReject ? (
            <>
              <h4>!!Sorry!!</h4>
              <p>!!Only audio files supported!!</p>
            </>
          ) : (
            <>
              <h4>Drag & Drop File Here</h4>
              <p>!!Only audio files supported!!</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropZone;

import React from "react";
import "./clone.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faStop,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { DropZone, Slider, ControlPanel, Dash } from "../../components";
import getBlobDuration from "get-blob-duration";
import axios from "axios";
import AudioReactRecorder, {
  RecordState,
} from "../../components/recorder/Recorder";
import logo from "../../assets/logo.svg";

import { Info } from "../../components";

// useRef is a hook that allows us to store a reference to a DOM node or a value throughout the lifetime of a component.
import { useState, useRef, useEffect } from "react";

const Clone = () => {
  // recording is a boolean value that indicates whether or not audio is currently being recorded, and setRecording is a function that allows us to update the value of recording
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);

  // audioUrl is an object that holds the recorded url and setAudioUrl is a function that allows us to update the value of audioUrl
  const [audioUrl, setAudioUrl] = useState();
  const audioRef = useRef(null);
  const genAudioRef = useRef(null);

  // converting base64 mels image to blob
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    console.log(b64Data);
    const byteCharacters = window.atob(
      b64Data.toString().replace("data:image/png;base64,", "")
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  // audio recorder

  const [recordState, setRecordState] = useState(null);
  const [micPosition, setMicPosition] = useState(false);

  const start = () => {
    setRecordState(RecordState.START);
    console.log("here here: ", RecordState.START);
    setRecording(true);
    setMicPosition(true);
    setIsAudioBlob(true);
  };

  const pause = () => {
    setRecordState(RecordState.PAUSE);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
    setRecording(false);
    setIsPlaying(false);
  };

  const onStop = (data) => {
    setAudioUrl(data.url);

    getBlobDuration(data.url).then(function (durr) {
      setDuration(durr);
      console.log(durr + " seconds");
    });

    setAudioBlob(data.blob);

    console.log(audioUrl);
    console.log("onStop: audio data", data);
  };

  // audio_player
  const [percentage, setPercentage] = useState(0);
  const [genPercentage, setGenPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isGenPlaying, setIsGenPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [genDuration, setGenDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [genCurrentTime, setGenCurrentTime] = useState(0);
  const [isAudioBlob, setIsAudioBlob] = useState(false);

  const onChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setPercentage(e.target.value);
  };

  const onGenChange = (e) => {
    const audio = genAudioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setGenPercentage(e.target.value);
  };

  const play = () => {
    const audio = audioRef.current;
    audio.volume = 0.9;

    if (!isPlaying) {
      setIsPlaying(true);
      audio.play();
    }

    audio.addEventListener("ended", () => {
      setIsPlaying(false);
      audio.pause();
      setPercentage(0);
      setCurrentTime(0);
    });

    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const genPlay = () => {
    const audio = genAudioRef.current;
    audio.volume = 0.9;

    if (!isGenPlaying) {
      setIsGenPlaying(true);
      audio.play();
    }

    audio.addEventListener("ended", () => {
      setIsGenPlaying(false);
      audio.pause();
      setGenPercentage(0);
      setGenCurrentTime(0);
    });

    if (isGenPlaying) {
      setIsGenPlaying(false);
      audio.pause();
    }
  };

  const getCurrDuration = (e) => {
    const percent = (e.currentTarget.currentTime / duration) * 100;
    const timeDur = e.currentTarget.currentTime;
    // console.log("percentage: ", percent);
    // console.log("timeDuration: ", timeDur);
    setPercentage(+percent);
    setCurrentTime(timeDur.toFixed(2));
  };

  const getCurrGenDuration = (e) => {
    const percent = (e.currentTarget.currentTime / genDuration) * 100;
    const timeDur = e.currentTarget.currentTime;
    // console.log("percentage: ", percent);
    // console.log("timeDuration: ", timeDur);
    setGenPercentage(+percent);
    setGenCurrentTime(timeDur.toFixed(2));
  };

  // saving audio file
  const [file, setFile] = useState(null);

  // input fields
  const [voiceActor, setVoiceActor] = useState("");
  const [sentence, setSentence] = useState("");

  const [errors, setErrors] = useState({});

  const [disable, setDisable] = useState(false);

  // POST request to generate mel spectrogram
  const handleGenerateMelSubmit = (e) => {
    e.preventDefault();

    const errors = validateInfo(sentence, voiceActor);
    setErrors(errors.errors);
    console.log(errors);

    if (!errors.haserror) {
      // Disable the button
      const mel_button = document.getElementById("mel-button");
      if (mel_button) {
        mel_button.disabled = true;
        setDisable(true);
      }

      fetch(audioUrl)
        .then((response) => response.blob())
        .then((blob) => {
          console.log("blob type", blob.type);
          const formData = new FormData();
          formData.append("text", sentence);
          formData.append(
            "audio",
            new File([blob], voiceActor + ".wav", {
              // type: blob.type,
              type: "audio/wav",
            })
          );
          console.log(blob);
          axios
            .post("https://rvs.ninja/generate_mel/", formData, {
              // .post("http://127.0.0.1:8000/generate_mel/", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              setPlotUrl(response.data.plot);
              setSpecs(response.data.specs);
              console.log("yaha xa hai specs: ", response.data.specs);

              // Re-enable the button
              if (mel_button) {
                mel_button.disabled = false;
                setDisable(false);
              }
            })
            .catch((error) => console.error(error));
        });
    }
  };

  function validateInfo(sentence, voiceActor) {
    let errors = {};
    let haserror = false;

    if (!sentence) {
      errors.sentence = "Text field is empty";
      haserror = true;
    }

    if (!voiceActor) {
      errors.voiceActor = "Voice actor field is empty";
      haserror = true;
    }

    return { errors, haserror };
  }

  // POST request to generate audio
  const handleGenerateAudioSubmit = (e) => {
    e.preventDefault();

    const errors = validateInfo(sentence, voiceActor);
    setErrors(errors.errors);
    console.log(errors);

    if (!errors.haserror) {
      // Disable the button
      const mel_button = document.getElementById("mel-button");
      const button = document.getElementById("audio-button");
      if (button) {
        button.disabled = true;
        mel_button.disabled = true;
        setDisable(true);
      }

      const audioFormData = new FormData();
      audioFormData.append("name", voiceActor);
      audioFormData.append("org_audio", audioBlob);
      audioFormData.append("mels", b64toBlob(plotUrl, "image/png"));
      audioFormData.append("text", sentence);
      audioFormData.append("specs", JSON.stringify(specs));

      axios
        .post("https://rvs.ninja/api/voices/", audioFormData, {
          // .post("http://127.0.0.1:8000/api/voices/", audioFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          getGeneratedAudio(response.data.id);
          setVoiceId(response.data.id);

          // Re-enable the button
          if (button) {
            button.disabled = false;
            mel_button.disabled = false;
            setDisable(false);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const [voiceid, setVoiceId] = useState(null);
  const [sendSuccess, setSendSuccess] = useState(false);

  // UPDATE request for favourite
  const handleFavourite = (e) => {
    e.preventDefault();

    axios
      .put(`https://rvs.ninja/api/voices/${voiceid}/update_favourite/`, {
        // .put(`http://127.0.0.1:8000/api/voices/${voiceid}/update_favourite/`, {
        favourite: true,
      })
      .then((response) => {
        console.log(response.data);
        setSendSuccess(true);
      })
      .catch((error) => console.error(error));
  };

  // useEffect(() => {
  //   axios.get('https://rvs.ninja/api/voices/')
  //     .then(response => {
  //       setData(response.data);
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // }, [sendSuccess])

  // generated audio initiating
  const [genAudio, setGenAudio] = useState(null);

  // Get request to get gen_audio
  const getGeneratedAudio = (id) => {
    // axios.get(`http://127.0.0.1:8000/api/voices/${id}/`).then((res) => {
    axios.get(`https://rvs.ninja/api/voices/${id}/`).then((res) => {
      setGenAudio(res.data.gen_audio);
    });
  };

  // getting mel spectrogram plot and specs from backend
  const [plotUrl, setPlotUrl] = useState(null);
  const [specs, setSpecs] = useState("");

  const [melGenerating, setMelGenerating] = useState(false);
  const [audioGenerating, setAudioGenerating] = useState(false);
  const [adding, setAdding] = useState(false);

  const genMelButton = () => {
    setMelGenerating(true);
    setAudioGenerating(false);
    setGenAudio(null);
    setPlotUrl(null);
  };

  const genAudioButton = () => {
    setAudioGenerating(true);
    setGenAudio(null);
  };

  const addToFavourite = () => {
    setAdding(true);
  };

  function handleDownloadClick(blobUrl, gotIndex) {
    const org_gen = ["org", "gen"];
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = file
      ? voiceActor
        ? org_gen[gotIndex] + "_" + voiceActor + ".wav"
        : org_gen[gotIndex] + "_" + file?.name
      : voiceActor
      ? org_gen[gotIndex] + "_" + voiceActor + ".wav"
      : org_gen[gotIndex] + "_audio.wav";
    link.click();
  }

  return (
    <div id="voiceClone">
      <Info />
      <div className="vc__clone section__margin">
        <div className="vc__clone-title">
          <Dash />
          <div className="vc__navbar-links_logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <input
          type="text"
          name="voiceActor"
          className="vc__voice-actor"
          value={voiceActor}
          placeholder="Voice Actor Name"
          onChange={(e) => setVoiceActor(e.target.value)}
          required
        />
        <div className="vc__container">
          <div className="vc__recording">
            <div
              className={
                micPosition ? "vc__voice-recorder" : "vc__voice_no-recorder"
              }
            >
              {!recording ? (
                <button id="record" className="record-button" onClick={start}>
                  <FontAwesomeIcon icon={faMicrophone} />
                </button>
              ) : (
                <>
                  <button id="stop" className="record-button" onClick={stop}>
                    <FontAwesomeIcon icon={faStop} />
                  </button>
                </>
              )}
            </div>

            <div className="audio-react-recorder-wrapper">
              <AudioReactRecorder
                state={recordState}
                onStop={onStop}
                backgroundColor="rgb(3, 27, 52)"
              />
            </div>
          </div>

          <div className="vc__drag&drop">
            <div className="vc__dropzone">
              <DropZone
                setFile={(uFile) => {
                  console.log(uFile.type, uFile.name);
                  setIsAudioBlob(false);
                  setFile(uFile);
                  setAudioUrl(URL.createObjectURL(uFile));
                  setAudioBlob(uFile);
                }}
              />
              {/* {file?.name} */}
            </div>
          </div>
        </div>

        {!recording && audioUrl ? (
          <>
            <div className="vc__audio_player">
              <div className="vc__audioname-download">
                <h4>
                  {voiceActor
                    ? file
                      ? file?.name
                      : voiceActor + ".wav"
                    : "Recorded Audio"}
                </h4>
                <button
                  className="download-button"
                  onClick={() => handleDownloadClick(audioUrl, 0)}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </div>
              <Slider percentage={percentage.toString()} onChange={onChange} />
              <audio
                ref={audioRef}
                onTimeUpdate={getCurrDuration}
                onLoadedData={(e) => {
                  !isAudioBlob &&
                    setDuration(e.currentTarget.duration.toFixed(2));
                }}
                src={audioUrl}
              ></audio>

              <ControlPanel
                play={play}
                isPlaying={isPlaying}
                duration={duration}
                currentTime={currentTime}
              />
            </div>

            <textarea
              type="text"
              value={sentence}
              rows="10"
              onChange={(e) => {
                setSentence(e.target.value);
                setMelGenerating(false);
                setAudioGenerating(false);
              }}
              placeholder="Please! Provide nepali text to generate the voice....."
            />

            <form onSubmit={handleGenerateMelSubmit}>
              <button
                id="mel-button"
                style={
                  disable ? { backgroundColor: "gray", color: "white" } : {}
                }
                type="submit"
                // disabled={sentence === ""}
                onClick={genMelButton}
              >
                Generate Mel
              </button>
            </form>

            {melGenerating === true ? (
              <>
                <div className="vc__mel">
                  {plotUrl === null ? (
                    // console.log("errors", errors)
                    errors.sentence || errors.voiceActor ? (
                      (errors.sentence && <p>{errors.sentence}</p>) ||
                      (errors.voiceActor && <p>{errors.voiceActor}</p>)
                    ) : (
                      <div>Loading Plot...</div>
                    )
                  ) : (
                    <img src={plotUrl} alt="Plot" />
                  )}
                </div>

                {plotUrl !== null ? (
                  <>
                    <form onSubmit={handleGenerateAudioSubmit}>
                      <button
                        id="audio-button"
                        style={
                          disable
                            ? { backgroundColor: "gray", color: "white" }
                            : {}
                        }
                        type="submit"
                        onClick={genAudioButton}
                      >
                        Generate Audio
                      </button>
                    </form>

                    {audioGenerating === true ? (
                      <>
                        {genAudio === null ? (
                          <div className="vc__gen-audio">
                            {errors.sentence || errors.voiceActor ? (
                              (errors.sentence && <p>{errors.sentence}</p>) ||
                              (errors.voiceActor && <p>{errors.voiceActor}</p>)
                            ) : (
                              <div>Generating Audio...</div>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="vc__audio_player">
                              <div className="vc__audioname-download">
                                <h4>
                                  {voiceActor
                                    ? file
                                      ? "Gen_" + file?.name
                                      : "Gen_" + voiceActor + ".wav"
                                    : "Generated Audio"}
                                </h4>
                                <button
                                  className="download-button"
                                  onClick={() =>
                                    handleDownloadClick(genAudio, 1)
                                  }
                                >
                                  <FontAwesomeIcon icon={faDownload} />
                                </button>
                              </div>
                              <Slider
                                percentage={genPercentage.toString()}
                                onChange={onGenChange}
                              />

                              <audio
                                ref={genAudioRef}
                                onTimeUpdate={getCurrGenDuration}
                                onLoadedData={(e) => {
                                  getBlobDuration(genAudio).then(function (
                                    durr
                                  ) {
                                    setGenDuration(durr);
                                    console.log(durr + " gen durr seconds");
                                  });
                                }}
                                src={genAudio}
                              ></audio>

                              <ControlPanel
                                play={genPlay}
                                isPlaying={isGenPlaying}
                                duration={genDuration}
                                currentTime={genCurrentTime}
                              />
                            </div>
                            <form onSubmit={handleFavourite}>
                              <button type="submit" onClick={addToFavourite}>
                                {adding
                                  ? sendSuccess
                                    ? "Added Successfully"
                                    : "Adding..."
                                  : "Add to Favourite"}
                              </button>
                            </form>
                          </>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Clone;

import React from "react";
import "./info.css";
import right_mel from "../../assets/right_mel_new.png";
import wrong_mel1 from "../../assets/wrong_mel1_new.png";
import wrong_mel2 from "../../assets/wrong_mel2_new.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCheckCircle,
//   faXmarkCircle
// } from "@fortawesome/free-solid-svg-icons";

const Info = () => {
  return (
    <div className="vc__info">
      <div className="vc__info-content">
        <h3>- - Must Read Before Clone - -</h3>
        <br/>
      </div>
      <div className="vc__info-list">
        <ul>
          <li>Please provide voice actor name.</li>
          <li><b>Users' Audio must be more than 5 sec long and Audio should be clear and noiseless as much as possible.</b></li>
          <li>Text must be in Nepali language <b>(i.e. Nepali Unicode)</b></li>
          <li>
            <b>Generated mel must be in following order. If mel are not found to be
            in following order then Please click on Generate Mel button again
            until the right mel is generated.</b>
          </li>
          <div className="vc__info-list_image">
            {/* <div style={{ color: "rgb(101, 193, 101)" }}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </div> */}
            <img src={right_mel} alt="Right Mel" />
          </div>
          <div className="vc__info-list_image_grid">
              <img src={wrong_mel1} alt="Wrong Mel" />
              <img src={wrong_mel2} alt="Wrong Mel" />
          </div>
          <li>If generated mel isn't of correct format after multiple tries then Please change the input audio. <b>(Audio should be clear and noiseless as much as possible)</b></li>
          <li>After correct mel is generated, Click on Generate Audio to generate clone audio.<b>(Please be patient until the audio is generated)</b></li>
        </ul>
      </div>
    </div>
  );
};

export default Info;

import React, { useEffect, useState } from "react";
import "./sample.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { Dash } from "..";

const Sample = ({ orgAudio, genAudio, date, title, actor, id, selected, setSelected }) => {
  const [orgPlay, setOrgPlay] = useState(false);
  const [genPlay, setGenPlay] = useState(false);
  const [orgaudio, setOriginalAudio] = useState(null);
  const [genaudio, setGenAudio] =useState(null)

  useEffect(()=>{
    if(orgAudio && genAudio){
      console.log("setting audio")
      setGenAudio(new Audio(genAudio))
      setOriginalAudio(new Audio(orgAudio))
    }
  }, [orgAudio, genAudio])

  useEffect(() => {
    if(id !== selected){
      orgaudio && orgaudio.pause();
      genaudio && genaudio.pause();
      setOrgPlay(false);
      setGenPlay(false);
      genaudio && (genaudio.currentTime = 0);
      orgaudio && (orgaudio.currentTime = 0);
    }
  }, [id, selected])

  const onOrgPlayChange = () => {
    setOrgPlay(true);
    setGenPlay(false);
    setSelected(id);
    orgaudio.play();
    genaudio.pause();
    genaudio.currentTime = 0;
  };

  const onOrgPauseChange = () => {
    setOrgPlay(false);
    orgaudio.pause();
    orgaudio.currentTime = 0;
  };

  if(orgaudio !== null){
    orgaudio.addEventListener('ended', onOrgPauseChange)
  }

  const onGenPlayChange = () => {
    setGenPlay(true);
    setOrgPlay(false);
    setSelected(id);
    genaudio.play();
    orgaudio.pause();
    orgaudio.currentTime = 0;
  };

  const onGenPauseChange = () => {
    setGenPlay(false);
    genaudio.pause();
    genaudio.currentTime = 0;
  };

  if(genaudio !== null){
    genaudio.addEventListener('ended', onGenPauseChange)
  }

  return (
    <div className="vc__blog-container_article">
      <div className="vc__blog-container_article-audio">
        {/* <img src={imgUrl} alt="blog"/> */}
        <div className="vc__blog-container_article_column">
          {!orgPlay ? (
            <button className="vc__blog-original" onClick={onOrgPlayChange}>
              <FontAwesomeIcon icon={faPlay} />
            </button>
          ) : (
            <button className="vc__blog-original" onClick={onOrgPauseChange}>
              <FontAwesomeIcon icon={faPause} />
            </button>
          )}
          <div>Original</div>
        </div>
        <div className="vc__blog-container_article_column">
          {!genPlay ? (
            <button className="vc__blog-generated" onClick={onGenPlayChange}>
              <FontAwesomeIcon icon={faPlay} />
            </button>
          ) : (
            <button className="vc__blog-generated" onClick={onGenPauseChange}>
              <FontAwesomeIcon icon={faPause} />
            </button>
          )}
          <div>Generated</div>
        </div>
      </div>
      <div className="vc__blog-container_article-content">
        <div>
          <p>{date}</p>
          <Dash />
          <h3>{title}</h3>
        </div>
        <p>Voice Actor: {actor}</p>
      </div>
    </div>
  );
};

export default Sample;

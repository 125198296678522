import React from "react";
import "./header.css";
import voice from "../../assets/voice.png";
import mic from "../../assets/mic.png";

const Header = () => {
  return (
    <div className="vc__header section__padding" id="home">
      <div className="vc__header-content">
        <h1 className="gradient__text">Nepali Voice Cloning</h1>
        <p>
          Feel the Power of <b>नेपाली-VC</b> as it Revolutionizes industries with cloned natural-sounding Nepali voices
        </p>
      </div>
      <div className="vc__header-image">
        <img src={voice} alt="voice" />
      </div>
    </div>
  );
};

export default Header;

import React from 'react';
import './footer.css';
import logo from "../../assets/logo.svg";
import manjil from "../../assets/manjil.jpg";
import ravi from "../../assets/ravi.jpg";
import sandesh from "../../assets/sandesh.jpg";
import pratik from "../../assets/pratik.png";

const Footer = () => {
  return (
    <div className='vc__footer section__padding'>
      <div className='vc__footer-heading'>
        <h1 className='gradient__text'>Developer's Team</h1>
      </div>
      <div className='vc__footer-members'>
        <div className='vc__footer-members_team'>
            <img src={manjil} alt="manjil"/>
            <h3>Manjil Karki</h3>
            <h4>KCE075BCT019</h4>
        </div>

        <div className='vc__footer-members_team'>
          <img src={pratik} alt="pratik" />
          <h3>Pratik Shakya</h3>
          <h4>KCE075BCT024</h4>
        </div>

        <div className='vc__footer-members_team'>
            <img src={ravi} alt="ravi" />
            <h3>Ravi Pandit</h3>
            <h4>KCE075BCT028</h4>
        </div>

        <div className='vc__footer-members_team'>
            <img src={sandesh} alt="sandesh" />
            <h3>Sandesh Acharya</h3>
            <h4>KCE075BCT040</h4>
        </div>

      </div>
      <div className='vc__footer-copyright'>
        <p>©2023<img src={logo} alt="logo" />All rights reserved. </p>
      </div>
      {/* <div className='vc__footer-copyright'>
        <p>@2021 GPT-3. All rights reserved.</p>
      </div> */}
    </div>
  )
}

export default Footer